<template>
  <div>
    <v-dialog v-model="dialog" persistent scrollable width="600">
      <v-card>
        <v-card-title>
          Superviseurs & Réponses
          <v-spacer/>
          <v-icon @click="close">mdi-close</v-icon>
        </v-card-title>
        <v-divider/>

        <v-card-text class="pa-0">


          <v-simple-table>
            <template v-slot:default>
              <thead>
              <tr>
                <th>
                  Superviseur
                </th>
                <th class="text-center" width="30%">
                  Progression
                </th>
                <th class="text-center">
                  Réponses
                </th>
              </tr>
              </thead>
              <tbody>

              <tr v-for="(supervisor, index) in asmCampaign.supervisors" :key="index">

                <td>
                  <div class="d-flex align-center py-4">
                    <v-avatar size="35">
                      <v-img v-if="supervisor.photo"
                             :src="FILE_URL + supervisor.photo"></v-img>
                      <v-img v-else :src="require('@/assets/avatar2.png')"></v-img>
                    </v-avatar>
                    <div>
                      <span class="ml-2 text-no-wrap font-weight-bold">{{ supervisor.name }}</span>
                    </div>
                  </div>
                </td>

                <td>
                  <div class="d-flex justify-space-between">
                    <strong>{{ supervisor.statistics.counter }}</strong>
                    <v-spacer/>
                    <strong>{{ supervisor.statistics.percent }}%</strong>
                  </div>

                  <v-progress-linear :color="$func.progressColor(supervisor.statistics.percent)"
                                     :value="supervisor.statistics.percent"
                                     height="5"
                                     rounded
                                     striped/>
                </td>

                <td class="text-center">
                  <v-btn color="primary"
                         text
                         depressed
                         @click="$refs.responsesDialog.open(asmCampaign,supervisor)">
                    Réponses
                    <v-icon right small>mdi-arrow-right</v-icon>
                  </v-btn>
                </td>

              </tr>
              </tbody>
            </template>
          </v-simple-table>


        </v-card-text>
      </v-card>
    </v-dialog>

    <ResponsesDialog ref="responsesDialog"/>

  </div>
</template>

<script>


import ResponsesDialog from "@/views/ASMCampaigns/components/ResponsesDialog.vue";

export default {
   components: {ResponsesDialog},
   data() {
      return {
         dialog: false,
         FILE_URL: process.env.VUE_APP_FILE_URL,
         asmCampaign: {},
      }
   },
   methods: {
      open(asmCampaign) {
         this.dialog = true
         this.asmCampaign = asmCampaign
      },
      close() {
         this.dialog = false
      }
   },
}
</script>

<style scoped>
.theme--dark .grey--text {
    color: #6f6c80 !important;
    caret-color: #6f6c80 !important;
}
</style>