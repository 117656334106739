<template>
  <div>
    <v-dialog v-model="dialog" fullscreen
              persistent
              scrollable>
      <v-card :loading="isLoading">

        <v-card-title>
          Réponses
          <v-spacer/>
          <v-icon @click="close">mdi-close</v-icon>
        </v-card-title>

        <v-divider/>

        <v-card-text class="pa-4">

          <div class="d-flex justify-space-between align-center pa-4 fs-15">

            <div>
               <span class="d-block font-weight-bold">
                  Campagne ASM : <span class="grey--text"> {{ asmCampaign.name }} </span>
              </span>
              <span class="d-block pt-2">
                  Superviseur : <span class="grey--text"> {{ supervisor.name }} </span>
             </span>
            </div>

            <div v-if="supervisor.statistics" class="font-weight-bold">
              <v-progress-circular :color="$func.progressColor(supervisor.statistics.percent)"
                                   :value="supervisor.statistics.percent" size="70">
                {{ supervisor.statistics.percent }}%
              </v-progress-circular>
              Total : {{ supervisor.statistics.counter }}
            </div>

          </div>


          <v-row v-if="supervisor.statistics" justify="center">
            <v-col class="text-center" cols="6">

              <v-btn-toggle v-model="state"
                            color="primary"
                            group
                            mandatory

              >

                <v-badge :content="total_realized_not_validated"
                         color="primary" offset-x="18" offset-y="18">
                  <v-btn active-class="primary--text" color="grey" large text value="realized">
                    Réalisé et non validé
                  </v-btn>
                </v-badge>

                <v-badge :content="total_unrealized" class="mx-4" color="primary" offset-x="18"
                         offset-y="18">
                  <v-btn active-class="primary--text" color="grey" large text value="unrealized">
                    Non réalisé
                  </v-btn>
                </v-badge>

                <v-badge :content="total_validated" color="primary" offset-x="18" offset-y="18">
                  <v-btn active-class="primary--text" color="grey" large text value="validated">
                    Validé
                  </v-btn>
                </v-badge>

              </v-btn-toggle>

            </v-col>
          </v-row>

          <div class="d-flex justify-space-between pt-8 pb-5">

            <div>
              <v-text-field v-model="keyword"
                            append-icon="mdi-magnify"
                            class="w-300"
                            clearable
                            dense
                            filled
                            hide-details
                            placeholder="Recherche..."
                            rounded single-line></v-text-field>
            </div>

            <v-spacer/>

            <v-btn color="primary"
                   class="mr-1"
                   text
                   @click="getASMCampaignResponses">
              <v-icon left>mdi-refresh</v-icon>
              Actualiser
            </v-btn>

            <v-btn v-if="state === 'realized'"
                   :disabled="!selectedIds.length"
                   color="success"
                   depressed
                   @click="validateDialog = true">
              <v-icon left>mdi-check</v-icon>
              Valider
            </v-btn>

          </div>

          <v-skeleton-loader v-if="isLoading" type="table"/>

          <div v-if="!isLoading && reponses.length > 0">

            <v-card-actions>
              <div>
                <v-select v-model="per_page"
                          :items="[10,20,50,100,200,300,400,500]"
                          :style="{width: '120px'}"
                          dense
                          hide-details
                          label="Ligne par page"
                          outlined></v-select>
              </div>
              <v-spacer/>
              <v-pagination v-if="total>per_page"
                            v-model="pagination.current"
                            :length="pagination.total"
                            circle
                            total-visible="6"
                            @input="onPageChange"
              ></v-pagination>
            </v-card-actions>

            <v-simple-table class="table-border">
              <template v-slot:default>
                <thead>
                <tr>

                  <th v-if="state === 'realized' || state === 'validated'"
                      class="text-left">
                    <v-icon v-if="state === 'realized'"
                            :color="selectedIds.length > 0 ? 'blue' : ''"
                            @click="toggleAll"
                            @mousedown.prevent>
                      {{ icon }}
                    </v-icon>
                    <v-icon v-if="state === 'validated'" color="success">mdi-check-circle</v-icon>
                  </th>

                  <th class="text-left">Photo</th>
                  <th id="key_26" class="text-left">{{ keywords.key_26 }}</th>
                  <th id="key_27" class="text-left">{{ keywords.key_27 }}</th>
                  <th id="key_28" class="text-left">{{ keywords.key_28 }}</th>
                  <th class="text-left">Téléphone-APP</th>
                  <th id="key_1" class="text-left">{{ keywords.key_1 }}</th>
                  <th class="text-left">Points gagnants</th>
                  <th class="text-left">Statut</th>
                  <th class="text-left">Détails</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="item in reponses"
                    :key="item.id"
                    :class="!selectedIds.includes(item.id) || 'v-data-table__selected'">

                  <td v-if="state === 'realized' || state === 'validated'">
                    <v-checkbox v-if="state === 'realized'"
                                v-model="selectedIds"
                                :value="item.id"
                                class="pt-0 mt-0"
                                color="blue" hide-details multiple></v-checkbox>
                    <v-icon v-if="state === 'validated'" color="success">mdi-check-circle-outline</v-icon>
                  </td>

                  <td>
                    <v-avatar size="38">
                      <v-img v-if="item.salepoint_account && item.salepoint_account.photo"
                             :src="FILE_URL + item.salepoint_account.photo"></v-img>
                      <v-img v-else :src="require('@/assets/avatar2.png')"></v-img>
                    </v-avatar>
                  </td>

                  <td>
                    {{ item.salepoint_account ? item.salepoint_account.salepoint_code : '-' }}
                  </td>

                  <td>
                    {{ item.salepoint_account.salepoint ? item.salepoint_account.salepoint.name : '-' }}
                  </td>

                  <td>
                    {{ item.salepoint_account.salepoint ? item.salepoint_account.salepoint.phone : '-' }}
                  </td>

                  <td>
                    <span v-if="item.salepoint_account && item.salepoint_account.salepoint_phone"
                          class="info--text">
                      {{ item.salepoint_account.salepoint_phone }}
                      </span>
                    <span v-else>-</span>
                  </td>

                  <td>
                    {{ item.salepoint_account.salepoint ? item.salepoint_account.salepoint.type : '-' }}
                  </td>

                  <td class="text-center">
                    <v-chip v-if="item.salepoint_account.salepoint && item.salepoint_account.asm_campaign_responses && item.salepoint_account.asm_campaign_responses.length"
                            :disabled="!getTotalWinningPoints(item.salepoint_account.asm_campaign_responses)"
                            color="blue"
                            dark
                            small>
                      {{ getTotalWinningPoints(item.salepoint_account.asm_campaign_responses) | toCurrency }}
                    </v-chip>
                    <span v-else>-</span>
                  </td>

                  <td>

                    <v-chip v-if="!item.salepoint_account.asm_campaign_responses.length" color="warning" dark small>
                      <v-icon left small>mdi-timer-sand</v-icon>
                      Non réalisé
                    </v-chip>

                    <v-chip v-else color="success" dark small>
                      <v-icon left small>mdi-playlist-check</v-icon>
                      Réalisé
                    </v-chip>

                  </td>

                  <td>
                    <v-btn :disabled="!item.salepoint_account.asm_campaign_responses.length"
                           color="primary"
                           depressed
                           text
                           @click="$refs.responsesDetailsDialog.open(item,state)"
                    >
                      <v-icon left>mdi-eye</v-icon>
                      Détails
                    </v-btn>
                  </td>

                </tr>
                </tbody>
              </template>
            </v-simple-table>

            <v-card-actions>
              <div>
                <v-select v-model="per_page"
                          :items="[10,20,50,100,200,300,400,500]"
                          :style="{width: '120px'}"
                          dense
                          hide-details
                          label="Ligne par page"
                          outlined></v-select>
              </div>
              <v-spacer/>
              <v-pagination v-if="total>per_page"
                            v-model="pagination.current"
                            :length="pagination.total"
                            circle
                            total-visible="6"
                            @input="onPageChange"
              ></v-pagination>
            </v-card-actions>

          </div>

          <NoResult v-if="!isLoading && reponses.length === 0"
                    class="text-center"/>


        </v-card-text>
      </v-card>
    </v-dialog>

    <ResponsesDetailsDialog ref="responsesDetailsDialog"/>

    <!--Validate Dialog-->

    <v-dialog v-model="validateDialog"
              max-width="400"
              persistent>
      <v-card :disabled="validateLoading"
              :loading="validateLoading"
              class="text-center">
        <v-card-text class="pa-6">
          <v-icon color="primary" size="110">mdi-information-outline</v-icon>

          <h2 class="text--primary mt-3">CONFIRMATION</h2>

          <p class="mt-5 fs-15 mb-5">
            Êtes-vous sûr de vouloir VALIDER ces Réponses
          </p>

          <p class="mt-5 fs-15 primary--text font-weight-bold">
            Total des POS sélectionnés : {{ selectedIds.length }}
          </p>

          <p class="mt-5 fs-15 primary--text font-weight-bold">
            Total des POS gagnés : {{ winningSalepointsSelected() }}
          </p>

          <p class="fs-15 mb-10 success--text font-weight-bold">
            Total des points gagnés : {{ totalWinningPointsSelected() | toCurrency }}
          </p>

          <v-btn class="mr-2" text
                 @click="validateDialog = false">
            <v-icon left>mdi-close</v-icon>
            Annuler
          </v-btn>

          <v-btn :disabled="validateLoading"
                 :loading="validateLoading"
                 color="primary"
                 depressed
                 @click="validate()">
            <v-icon left>mdi-check</v-icon>
            Confirmer
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import {HTTP} from "@/http-common";
import NoResult from "@/components/NoResult.vue";
import ResponsesDetailsDialog from "@/views/ASMCampaigns/components/ResponsesDetailsDialog.vue";

export default {
    components: {ResponsesDetailsDialog, NoResult},
    data() {
        return {
            state: 'realized',

            validateLoading: false,
            validateDialog: false,

            isLoading: false,
            dialog: false,
            FILE_URL: process.env.VUE_APP_FILE_URL,
            keywords: this.$store.state.keyword,
            asmCampaign: {},

            supervisor: {},
            keyword: null,
            reponses: [],
            total: 0,
            per_page: 10,
            pagination: {
                current: 1,
                total: 0
            },

            total_realized_not_validated: 0,
            total_unrealized: 0,
            total_validated: 0,

            selectedIds: []
        }
    },
    methods: {
        open(asmCampaign, supervisor) {
            this.dialog = true
            this.supervisor = supervisor
            this.asmCampaign = asmCampaign
            this.getASMCampaignResponses()
        },
        close() {
            this.dialog = false
        },
        getASMCampaignResponses() {
            this.isLoading = true
            this.$Progress.start()
            HTTP.get('/asm-campaigns/' + this.asmCampaign.id + '/responses?page=' + this.pagination.current, {
                params: {
                    state: this.state,
                    supervisor_id: this.supervisor.id,
                    keyword: this.keyword,
                    per_page: this.per_page,
                }
            }).then((res) => {
                this.isLoading = false
                this.$Progress.finish()

                this.reponses = res.data.data.data;
                this.pagination.current = res.data.data.current_page;
                this.pagination.total = res.data.data.last_page;
                this.total = res.data.data.total;

                this.total_realized_not_validated = res.data.total_realized_not_validated;
                this.total_unrealized = res.data.total_unrealized;
                this.total_validated = res.data.total_validated;

            }).catch(err => {
                this.$Progress.fail()
                this.isLoading = false
                console.log(err)
            })
        },
        onPageChange() {
            this.getASMCampaignResponses()
        },

        /**
         * Get total of winning  points by row
         * @returns {number}
         */
        getTotalWinningPoints(responses) {
            return responses.filter(item => item.response === 'compliant').reduce(function (sum, res) {
                return sum + res.asm_campaign_k_p_i.winning_points;
            }, 0)
        },
        /**
         * Get total of winning  points selected
         * @returns {number}
         */
        totalWinningPointsSelected() {
            let _vm = this
            let selectedResponses = this.reponses.filter(item => {
                return _vm.selectedIds.includes(item.id)
            })

            let responses = []
            selectedResponses.map((el) => {
                el.salepoint_account.asm_campaign_responses.map((res) => {
                    responses.push(res)
                })
            });

            return responses.filter(item => item.response === 'compliant').reduce(function (sum, res) {
                return sum + res.asm_campaign_k_p_i.winning_points;
            }, 0)
        },

        /**
         * Get total of winning salepoints whene select rows
         * @returns {number}
         */
        winningSalepointsSelected() {
            let _vm = this
            let selectedResponses = this.reponses.filter(item => {
                return _vm.selectedIds.includes(item.id)
            })

            let responses = []
            selectedResponses.map((el) => {
                let total = el.salepoint_account.asm_campaign_responses.filter(item => item.response === 'compliant').reduce(function (sum, res) {
                    return sum + res.asm_campaign_k_p_i.winning_points;
                }, 0)
                responses.push(total)
            });

            return responses.filter(el => el > 0).length
        },
        toggleAll() {
            if (this.selectedIds.length === this.reponses.length) {
                this.selectedIds = []
            } else {
                this.selectedIds = this.reponses.map(el => el.id)
            }
        },
        validate() {
            this.validateLoading = true

            let _vm = this
            let selectedResponses = this.reponses.filter(item => {
                return _vm.selectedIds.includes(item.id)
            })

            /**
             * Pars All responses
             * @type {*[]}
             */
            let responses = []
            selectedResponses.map((el) => {
                el.salepoint_account.asm_campaign_responses.map((res) => {
                    responses.push({
                        'asm_campaign_id': res.asm_campaign_id,
                        'supervisor_id': res.supervisor_id,
                        'salepoint_account_id': res.salepoint_account_id,
                        'asm_campaign_k_p_i_id': res.asm_campaign_k_p_i_id,
                        'winning_points': res.asm_campaign_k_p_i.winning_points,
                        'response': res.response,
                    })
                })
            })

            HTTP.post('/asm-campaigns/validate-responses', {
                responses: responses
            }).then(() => {
                this.$successMessage = 'Ces réponses ont été validées avec succès !'
                this.validateLoading = false
                this.validateDialog = false

                /***
                 * Add to amount spent in asm campaigns list
                 * @type {*}
                 */
                let totalWinn = responses.filter(item => item.response === 'compliant').reduce(function (sum, res) {
                    return sum + res.winning_points;
                }, 0)

                this.asmCampaign.amount_spent += totalWinn
                this.getASMCampaignResponses()
                /**
                 *Refresh counter of badge
                 * @type {number}
                 */
                this.supervisor.statistics.total_validated += this.selectedIds.length
                this.selectedIds = []
            }).catch(err => {
                this.validateLoading = false
                this.$errorMessage = "Une erreur ç'est produit veuillez le corriger."
                console.log(err)
            })
        }
    },
    watch: {
        keyword() {
            this.getASMCampaignResponses();
        },
        per_page() {
            this.pagination.current = 1
            this.getASMCampaignResponses();
        },
        state() {
            this.pagination.current = 1
            this.getASMCampaignResponses();
        },
    },
    computed: {
        icon() {
            if (this.selectedIds.length === this.reponses.length) return 'mdi-close-box'
            if (this.selectedIds.length > 0 && this.selectedIds.length !== this.reponses.length) return 'mdi-minus-box'
            return 'mdi-checkbox-blank-outline'
        },
    },
}
</script>

<style scoped>
.theme--dark .grey--text {
    color: #6f6c80 !important;
    caret-color: #6f6c80 !important;
}
</style>