<template>
  <div>
    <v-dialog v-model="dialog" scrollable width="600">
      <v-card v-if="response.salepoint_account">

        <v-card-title>
          Détails de réponse
          <v-spacer/>
          <v-icon @click="close">mdi-close</v-icon>
        </v-card-title>

        <v-divider/>

        <v-card-subtitle>

          <div class="mt-5 font-weight-bold">

                    <span>
                       Nom de POS : <span class="grey--text"> {{ response.salepoint_account.salepoint.name }} </span>
                    </span>

            <span class="mx-5">
                         Code de POS : <span class="grey--text"> {{ response.salepoint_account.salepoint.code }} </span>
                       </span>
          </div>
        </v-card-subtitle>

        <v-divider/>

        <v-card-text class="pa-8">

          <v-alert v-if="state === 'realized'" border="left" dense prominent text type="info">
            Vous pouvez changer n'importe quelle réponse.
          </v-alert>

          <div v-for="(res,index) in response.salepoint_account.asm_campaign_responses" :key="index">
            <v-card class="mb-2" outlined>
              <v-card-title>
                {{ res.asm_campaign_k_p_i.k_p_i.title }}
                <v-spacer/>
                <span :class="res.response !== 'incompliant' || 'text-decoration-line-through grey--text'"
                      class="text-primary">
                                    {{ res.asm_campaign_k_p_i.winning_points | toCurrency }}
                                </span>
              </v-card-title>
              <v-card-text>
                {{ res.asm_campaign_k_p_i.k_p_i.description }}
              </v-card-text>

              <v-card-actions>
                <v-spacer/>

                <v-chip-group v-model="res.response" mandatory>

                  <v-chip :class="state !== 'realized'  ? 'is-readonly' : ''"
                          active-class="success"
                          filter value="compliant">
                    Conforme
                  </v-chip>

                  <v-chip :class="state !== 'realized'  ? 'is-readonly' : ''"
                          active-class="red white--text"
                          filter
                          value="incompliant">
                    Non conforme
                  </v-chip>

                </v-chip-group>

              </v-card-actions>

            </v-card>
          </div>

        </v-card-text>

        <v-divider/>

        <v-card-title class="text-primary mb-1">
          <v-spacer/>
          Total :
          {{ totalWinningPoits() | toCurrency }}
        </v-card-title>

      </v-card>
    </v-dialog>

  </div>
</template>

<script>
export default {
    data() {
        return {
            isLoading: false,
            dialog: false,
            state: null,
            response: {},
        }
    },
    methods: {
        totalWinningPoits() {
            return this.response.salepoint_account.asm_campaign_responses.filter(item => item.response === 'compliant').reduce(function (sum, res) {
                return sum + res.asm_campaign_k_p_i.winning_points;
            }, 0)
        },
        open(response, state) {
            this.dialog = true
            this.state = state
            this.response = response
        },
        close() {
            this.dialog = false
        }
    },
}
</script>

<style scoped>
.theme--dark .grey--text {
    color: #6f6c80 !important;
    caret-color: #6f6c80 !important;
}

.is-readonly {
    pointer-events: none;
}
</style>