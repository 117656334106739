<template>
  <div>
    <v-dialog v-model="dialog" persistent scrollable width="900">
      <v-card>
        <v-card-title>
          Analyse de campagne ASM : <span class="grey--text ml-2"> {{ asmCampaignName }}</span>
          <v-spacer/>
          <v-icon @click="close">mdi-close</v-icon>
        </v-card-title>
        <v-divider/>

        <v-card-text class="pa-8">

          <v-skeleton-loader v-if="isLoading" type="article,article,article"/>

          <div v-else>

            <v-row align="center" justify="center">
              <v-col cols="4" class="text-center">

                <v-progress-circular :color="$func.progressColor(results.percent)"
                                     :value="results.percent"
                                     size="150"
                                     width="10">
                  <h2>{{ results.percent }}%</h2>
                </v-progress-circular>
              </v-col>
              <v-col cols="8">
                <h3>Total des pos réalisées : {{ results.counter }} </h3>
                <v-simple-table class="mt-8">
                  <template v-slot:default>
                    <thead>
                    <tr>
                      <th>
                        Superviseur
                      </th>
                      <th class="text-center" width="40%">
                        Progression
                      </th>
                    </tr>
                    </thead>
                    <tbody>

                    <tr v-for="(supervisor,index) in results.supervisors" :key="index">

                      <td>
                        <div class="d-flex align-center py-2">
                          <v-avatar size="38">
                            <v-img v-if="supervisor.supervisor_photo"
                                   :src="FILE_URL + supervisor.supervisor_photo"></v-img>
                            <v-img v-else :src="require('@/assets/avatar2.png')"></v-img>
                          </v-avatar>
                          <div>
                            <span class="ml-2 text-no-wrap font-weight-bold">{{ supervisor.supervisor_name }}</span>
                          </div>
                        </div>
                      </td>

                      <td>
                        <div class="d-flex justify-space-between">
                          <strong>{{ supervisor.counter }}</strong>
                          <v-spacer/>
                          <strong>{{ supervisor.percent }}%</strong>
                        </div>

                        <v-progress-linear :color="$func.progressColor(supervisor.percent)"
                                           :value="supervisor.percent"
                                           height="5"
                                           rounded
                                           striped/>
                      </td>
                    </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
            </v-row>



            <v-simple-table class="mt-8 table-border">
              <template v-slot:default>
                <thead>
                <tr>
                  <th>
                    KPI
                  </th>
                  <th class="text-center" width="22%">
                    Conforme
                  </th>
                  <th class="text-center" width="22%">
                    Non conforme
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(kpi,index) in results.KPIs" :key="index">

                  <td>
                    <div class="pa-2">
                      <strong class="d-block">{{ kpi.kpi_title }}</strong>
                      <span>{{ kpi.kpi_description }}</span>
                    </div>
                  </td>


                  <td>
                    <div class="d-flex justify-space-between">
                      <strong>{{ kpi.compliant.total }}</strong>
                      <v-spacer/>
                      <strong>{{ kpi.compliant.percent }}%</strong>
                    </div>

                    <v-progress-linear :value="kpi.compliant.percent"
                                       color="success"
                                       height="5"
                                       rounded
                                       striped/>
                  </td>

                  <td class="text-center">
                    <div class="d-flex justify-space-between">
                      <strong>{{ kpi.incompliant.total }}</strong>
                      <v-spacer/>
                      <strong>{{ kpi.incompliant.percent }}%</strong>
                    </div>

                    <v-progress-linear :value="kpi.incompliant.percent"
                                       color="orange"
                                       height="5"
                                       rounded
                                       striped/>
                  </td>


                </tr>
                </tbody>
              </template>
            </v-simple-table>

          </div>

        </v-card-text>

      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import {HTTP} from "@/http-common";

export default {
   data() {
      return {
         dialog: false,
         asmCampaignName: '',
         FILE_URL: process.env.VUE_APP_FILE_URL,
         isLoading: false,
         results: {
            percent: 0,
            counter: '0/0',
            supervisors: [],
            KPIs: [],
         },
      }
   },
   methods: {
      resetResults() {
         this.results = {
            percent: 0,
            counter: '0/0',
            supervisors: [],
            KPIs: [],
         }
      },
      open(asmCampaign) {
         this.resetResults()
         this.asmCampaignName = asmCampaign.name
         this.dialog = true
         this.getAnalyses(asmCampaign.id)
      },
      getAnalyses(id) {
         this.isLoading = true
         HTTP.get('/asm-campaigns/' + id + '/analyses').then(res => {
            this.results = res.data.data
            this.isLoading = false
         }).catch(err => {
            this.isLoading = false
            console.log(err)
         })
      },
      close() {
         this.dialog = false
      }
   },
}
</script>

<style scoped>
.theme--dark .grey--text {
    color: #6f6c80 !important;
    caret-color: #6f6c80 !important;
}
</style>